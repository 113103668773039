var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "push"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.title) + " Search")]), _c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "data-cy": "lookahead-search",
      "placeholder": _vm.searchText
    },
    on: {
      "input": _vm.onInput
    }
  }), _vm.searchIcon ? _c('div', {
    staticClass: "input-group-append"
  }, [_vm._m(0)]) : _vm._e(), _vm.searchByOptions.length > 0 ? _c('div', {
    staticClass: "input-group-append mr-7"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchBy,
      expression: "searchBy"
    }],
    staticClass: "form-control form-control-alt",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.searchBy = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.onSearchBy]
    }
  }, _vm._l(_vm.searchByOptions, function (search, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": search.value
      }
    }, [_vm._v(_vm._s(search.display))]);
  }), 0)]) : _vm._e(), _vm.searchByOptions.length > 0 ? _c('div', {
    staticClass: "mr-3 white-space d-flex align-items-center"
  }, [_c('strong', [_vm._v("Sort By")])]) : _vm._e(), _c('div', {
    staticClass: "input-group-append"
  }, [!_vm.noDropdown ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sort,
      expression: "sort"
    }],
    staticClass: "form-control form-control-alt",
    attrs: {
      "placeholder": "Sort by..."
    },
    on: {
      "input": _vm.onSort,
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.sort = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "name:1"
    }
  }, [_vm._v("Name (A-Z)")]), _c('option', {
    attrs: {
      "value": "name:-1"
    }
  }, [_vm._v("Name (Z-A)")]), _c('option', {
    attrs: {
      "value": "createdAt:-1"
    }
  }, [_vm._v("Created Date (Newest)")]), _c('option', {
    attrs: {
      "value": "createdAt:1"
    }
  }, [_vm._v("Created Date (Oldest)")])]) : _vm._e()])])]), !_vm.noDropdown && _vm.results.total > 0 ? _c('span', {
    staticClass: "d-flex justify-content-between"
  }, [_c('label', [_vm._v("Results (Page " + _vm._s(_vm.page) + ")")]), _c('label', [_vm._v("Showing " + _vm._s(_vm.results.skip + 1) + " - " + _vm._s(_vm.results.skip + _vm.results.data.length) + " out of " + _vm._s(_vm.results.total))])]) : _vm._e(), !_vm.loading && _vm.results.total > 0 ? _c('div', [_c('div', {
    staticClass: "lookahead-results-overflow"
  }, [_c('div', {
    staticClass: "list-group"
  }, _vm._l(_vm.results.data, function (result) {
    return _c('a', {
      key: result._id,
      staticClass: "list-group-item list-group-item-action",
      attrs: {
        "href": "#"
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          return _vm.onSelect(result);
        }
      }
    }, [_vm._t("result", null, {
      "data": result
    })], 2);
  }), 0)]), _vm.results.total > _vm.results.data.length ? _c('ul', {
    staticClass: "pagination mt-3"
  }, [_c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.page === 1
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": _vm.onClickBack
    }
  }, [_vm._m(1), _c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Previous")])])]), _c('li', {
    staticClass: "page-item",
    class: {
      disabled: _vm.page >= _vm.results.total / _vm.limit
    }
  }, [_c('button', {
    staticClass: "page-link",
    on: {
      "click": _vm.onClickNext
    }
  }, [_vm._m(2), _c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Next")])])])]) : _vm._e()]) : !_vm.loading && _vm.results.total === 0 ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No results found.")]) : _c('Spinner', {
    staticClass: "mb-3"
  }, [_vm._v("Searching...")])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "input-group-text border-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-search"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-left"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('i', {
    staticClass: "fa fa-angle-right"
  })]);

}]

export { render, staticRenderFns }